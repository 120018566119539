import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"

import './documentation.component.less';

import IconPdf from "../../assets/img/icon/icon_pdf.svg"
import IconGit from "../../assets/img/icon/icon_git.svg"
import IconLink from "../../assets/img/icon/icon_link.svg"

import {Link} from "gatsby";
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const DocumentationComponent = () => {
    const {i18n, t} = useTranslation()
    const {originalPath} = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionDocumentation")}
                 lang={i18n.language} title={t("titleDocumentation")}
                 link={"https://cityair.ru/" + i18n.language + originalPath}/>
            <div
                className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div
                                className="color_purple documentation_main_name">{t("documentation")}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div
                                className="documentation_main_title">{t("projectDocumentationAnd")}</div>
                        </div>
                        <div
                            className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="documentation_main_description_1">
                                {t("onThisPageYou")}
                            </div>
                            <div
                                className="color_gray documentation_main_description_2">
                                {t("weConstantlyImprove")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container documentation_block_6__container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__title">
                            {t("projectDocumentation")}
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("documentationForSpecialists")}
                        </div>
                    </div>
                    {i18n.language === 'ru' &&
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a href="https://developers.cityair.ru/" target="_blank">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" target="_blank" href="https://developers.cityair.ru/">developers.cityair.ru</a>&nbsp;&mdash; {t("developersAPI")}
                            </div>
                        </div>
                    </div>
                    }
                    {i18n.language === 'en' &&
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a download=""
                                   href="https://github.com/cityairllc/cityair_python_api">
                                    <img alt="" src={IconGit}/>
                                </a>
                            </div>
                            <div className="git_hub documentation_block_6__item-title">
                                <a className="color_purple" target="_blank"
                                   rel="noreferrer"
                                   href="https://github.com/cityairllc/cityair_python_api">{t("gitHub")}</a>&nbsp;&mdash; {t("APIlibrary")}
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("instructions")}
                        </div>
                    </div>
                    {i18n.language === 'ru' &&
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a label="Руководство по эксплуатации CityAir 1.1 rus"
                                   download
                                   href="/docs/Руководство%20по%20эксплуатации%20CityAir%201.1%20rus.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" download
                                   href="/docs/Руководство%20по%20эксплуатации%20CityAir%201.1%20rus.pdf">
                                    {t("userManual")}</a>&nbsp;&mdash; {t("descriptionOfMonitoring")}
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                            <div className="documentation_block_6__icon">
                                <a download
                                   href="/docs/Инструкция_по_монтажу.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   download
                                   href="/docs/Инструкция_по_монтажу.pdf">{t("APIForAccessMonitoring")}</a>
                            </div>
                        </div>
                    </div>}
                    {i18n.language === 'ru' && <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a label="Руководство пользователя CityAir Plume"
                                   download
                                   href="/docs/plume/cityair-plume.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" download
                                   href="/docs/plume/cityair-plume.pdf">
                                    {t("userManualPlums")}</a>&nbsp;&mdash; {t("sysVizual")}
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                            <div className="documentation_block_6__icon">
                                <a label="Руководство по эксплуатации CityAir 1.1 rus"
                                   download
                                   href="/docs/cityScreen_Basic_user_manual.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" download
                                   href="/docs/cityScreen_Basic_user_manual.pdf">
                                    {t("userManualPlums")}</a>&nbsp;&mdash; {t("cityScreenBasic")}
                            </div>
                        </div>
                    </div>}

                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a label="Руководство пользователя CityAir Forecast"
                                   download
                                   href="/docs/forecast/cityair-forecast.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" download
                                   href="/docs/forecast/cityair-forecast.pdf">
                                    {t("userManualPlums")}</a>&nbsp;&mdash; {t("cityScreenForecast")}
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>
                    </div>

                    {i18n.language === 'en' &&
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a label="CityAir_User_Manual_Monitoring_System"
                                   download
                                   href="/docs/en/CityAir_User_Manual_Monitoring_System.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" download
                                   href="/docs/en/CityAir_User_Manual_Monitoring_System.pdf">
                                    {t("userManual")}</a>&nbsp;&mdash; {t("descriptionOfMonitoring")}
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>
                    </div>}

                    {i18n.language === 'ru' &&
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("licensesAndCertificates")}
                        </div>
                    </div>
                    }
                    {i18n.language === 'ru' &&
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/Лицензия_РГМ.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/Лицензия_РГМ.pdf">
                                    {t("licensesRoshydromet")}</a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/certificate_CityAir_Dust.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/certificate_CityAir_Dust.pdf">
                                    {t("RSICertificate")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/Type_approval_certificate_G_module.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/Type_approval_certificate_G_module.pdf">
                                    {t("expansionModulesG")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>


                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Basic.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Basic.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Basic")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Forecast.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Forecast.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Forecast")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Plume.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Plume.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Plume")}
                                </a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/918428/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/918428/">
                                    {t("registrySoftwareBasic")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/309010/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/309010/">
                                    {t("registrySoftwarePlume")}
                                </a>
                            </div>
                        </div>


                    </div>

                    }

                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("equipment")}
                        </div>
                    </div>
                    <div
                        className="row color_black_light documentation_block_6__row documentation_block_6__row-end">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                {i18n.language === 'ru' && <a download
                                                              href="/docs/Datasheet_CityAir.pdf"><img
                                    alt="" src={IconPdf}/></a>}
                                {i18n.language === 'en' && <a download
                                                              href="/docs/en/Datasheet_CA2_G_ENG.pdf"><img
                                    alt="" src={IconPdf}/></a>}
                            </div>
                            {i18n.language === 'ru' &&
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   download href="/docs/Datasheet_CityAir.pdf">
                                    {t("datasheet")}</a>&nbsp;&mdash; {t("cityAirMonitoring")}
                            </div>
                            }
                            {i18n.language === 'en' &&
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   download
                                   href="/docs/en/Datasheet_CA2_G_ENG.pdf">
                                    {t("datasheet")}</a>&nbsp;&mdash; {t("cityAirMonitoring")}
                            </div>
                            }
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                    </div>
                    {i18n.language === 'ru' &&
                    <div className="row color_black_light">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string-certification">
                            <div>
                                <div className="documentation_block_6__link">
                                    {t("reportOnTheSpecial")}<a target="_blank"
                                                                className="color_purple"
                                                                href="/docs/certification.jpg">&nbsp;{t("forLink")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

DocumentationComponent.propTypes = {}

DocumentationComponent.defaultProps = {}

export default DocumentationComponent
